import React, { useEffect , useState } from "react";
import ReferralDetailInfo from "./ReferralDetailInfo";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {getReferralDetails, updateReferral} from "../../Redux/Actions/ReferralActions";
import Loading from "../LoadingError/Loading";
import Message from "../LoadingError/Error";
import moment from "moment";

const ReferralDetailmain = (props) => {
  const { referralId } = props;
  const dispatch = useDispatch();

  const referralDetails = useSelector((state) => state.referralDetails);
  const { loading, error, referral } = referralDetails;
  const [remarks, setRemarks] = useState("");
  const [status, setStatus] = useState("");

  const handleInputChange = (e, remarks) => {
    setRemarks( e.target.value );
  };

  const handleSelectChange = (e) => {
    setStatus( e.target.value );
  };

  const buttonClickHandler = async() => {
    if (status != "" || remarks != "") {
      try {
      
  
        const payload = {
          remarks: remarks,
          status: status,
          referralID: referral._id
        };
 
        let result = await dispatch(updateReferral(payload));
  
        setTimeout(function () { window.location.reload()}, 3000);

      } catch (error) {
       
        setTimeout(function () { window.location.reload()}, 3000);
        
        
      }
    }
  }


  useEffect(() => {
    dispatch(getReferralDetails(referralId));
  }, [dispatch, referralId]);



  return (
    <section className="content-main">
      <div className="content-header">
        <Link to="/referralspaycompleted" className="btn btn-dark text-white">
          Back To Referrals Pay Completed
        </Link>
      </div>

      {loading ? (
        <Loading />
      ) : error ? (
        <Message variant="alert-danger">{error}</Message>
      ) : (
        <div className="card">
          <header className="card-header p-3 Header-green">
            <div className="row align-items-center ">
              <div className="col-lg-4 col-md-6">
                <span>
                  <i className="far fa-calendar-alt mx-2"></i>
                  
                    {moment(referral.createdAt).format("llll")}
                  
                </span>
                <br />
                <large className="text-white mx-3 ">
                  <b>
                Referral Code: {referral.referral_code}</b>
                </large><br />
                <large className="text-white mx-3 ">
                  <b>
                Referral Count: {referral.referral_count}</b>
                </large><br />
                <large className="text-white mx-3 ">
                  <b>
                Referral Status: {referral.status}</b>
                </large><br />
                <large className="text-white mx-3 ">
                  <b>
                Payment Status: {referral.referral_pay_status}</b>
                </large><br />
                
                <large className="text-white mx-3 ">
                  <b>
                Attempt: {referral.attempt_count}</b>
                </large>
          </div>
              <div className="col-lg-8 col-md-6 ms-auto d-flex justify-content-end align-items-center">
                <label>Remarks</label>
                <input
                            type="text"
                            value={remarks}
                            onChange={(e) => handleInputChange(e, remarks)}
                            placeholder="Remarks"
                            style={{ marginRight: "5px" , width: "8rem"}}
                           
                          />
                          
                  

                <select
                  className="form-select d-inline-block"
                  style={{ maxWidth: "200px" }}
                  onChange={(handleSelectChange)}
                >
                  
                  <option>Initiated</option>
                  <option>Success</option>
                  
                </select>
              <button
              onClick={(buttonClickHandler)}
              >Save</button>
              </div>
            </div>
          </header>
          <div className="card-body">
            {/* Order info */}
            <ReferralDetailInfo referral={referral} />

          </div>
        </div>
      )}
    </section>
  );
};

export default ReferralDetailmain;
