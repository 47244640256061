/* eslint-disable jsx-a11y/iframe-has-title */
import React from "react";

const SaleStatistics = () => {
  return (
    <div>
      
        
          {/* <iframe
            style={{
              background: "#FFFFFF",
              border: "none",
              borderRadius: "2px",
              boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2);",
              width: "100%",
              height: "350px",
            }}
            src="https://charts.mongodb.com/charts-shoeshoptutorial-bzbxw/embed/charts?id=28397e9a-cc52-45f2-8da0-7a9a760c2f6d&maxDataAge=3600&theme=light&autoRefresh=true"
            
          ></iframe> */}
          <iframe style={{background: "#FFFFFF", border: "none", borderradius: "2px", boxshadow: "0 2px 10px 0 rgba(70, 76, 79, .2)" ,width: "70vw", height: "80vh"}}  src="https://charts.mongodb.com/charts-project-0-xesoq/embed/dashboards?id=65cb15d5-ca18-4382-848a-3f5a877bfe55&theme=dark&autoRefresh=true&maxDataAge=60&showTitleAndDesc=false&scalingWidth=scale&scalingHeight=scale"></iframe>
       
      
    </div>
  );
};

export default SaleStatistics;
