import React, { useEffect } from "react";
import "./App.css";
import "./responsive.css";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeScreen from "./screens/HomeScreen";
import OrderScreen from "./screens/OrderScreen";
import ReferralScreenToPay from "./screens/ReferralScreenToPay";
import ReferralScreenAll from "./screens/ReferralScreenAll";
import ReferralScreenPartial from "./screens/ReferralScreenPartial";
import ReferralScreenPayCompleted from "./screens/ReferralScreenPayCompleted";
import OrderDetailScreen from "./screens/OrderDetailScreen";
import ReferralDetailScreenToPay from "./screens/ReferralDetailScreenToPay";
import ReferralDetailScreenAll from "./screens/ReferralDetailScreenAll";
import ReferralDetailScreenPartial from "./screens/ReferralDetailScreenPartial";
import ReferralDetailScreenPayCompleted from "./screens/ReferralDetailScreenPayCompleted";
import Login from "./screens/Login";
import UsersScreen from "./screens/UsersScreen";
import NotFound from "./screens/NotFound";
import PrivateRouter from "./PrivateRouter";


function App() {


  return (
    <>
      <Router>
        <Switch>
          <PrivateRouter path="/" component={HomeScreen} exact />
          <PrivateRouter path="/orders" component={OrderScreen} />
          <PrivateRouter path="/order/:id" component={OrderDetailScreen} />
          <PrivateRouter path="/users" component={UsersScreen} />
          <PrivateRouter path="/referralstopay" component={ReferralScreenToPay} />
          <PrivateRouter path="/referralspartial" component={ReferralScreenPartial} />
          <PrivateRouter path="/referralspaycompleted" component={ReferralScreenPayCompleted} />
          <PrivateRouter path="/referralsall" component={ReferralScreenAll} />
          <PrivateRouter path="/ReferralDetailScreenToPay/:id" component={ReferralDetailScreenToPay} />
          <PrivateRouter path="/ReferralDetailScreenAll/:id" component={ReferralDetailScreenAll} />
          <PrivateRouter path="/ReferralDetailScreenPartial/:id" component={ReferralDetailScreenPartial} />
          <PrivateRouter path="/ReferralDetailScreenPayCompleted/:id" component={ReferralDetailScreenPayCompleted} />




          <Route path="/login" component={Login} />
          <PrivateRouter path="*" component={NotFound} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
