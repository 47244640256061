import React from "react";

const ReferralDetailInfo = (props) => {
  const { referral } = props;
  return (
    <div className="row mb-5 order-info-wrap">
      <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-user"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Customer</h6>
            <p className="mb-1">
              {referral.customer.first_name} {referral.customer.last_name}<br />
              <a href={`mailto:${referral.customer.email}`}>{referral.customer.email}</a>
            </p>
          </div>
        </article>
      </div>
      <div className="col-md-6 col-lg-4" >
      <h6 className="mb-1">Remarks</h6>
      <p className="mb-1">
              {referral.remarks} <br />
              
            </p>
      </div>
      <div >
        <table className="table">
          <thead>
            <tr>
            <th>Name</th>
            <th>Bank Name</th>
            <th>Acc No</th>
            <th>IFSC</th>
            <th>UPI ID</th>
            <th>UPI Mob</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{referral.customer.bank_details.account_holder_name}</td>
              <td>{referral.customer.bank_details.bank_name}</td>
              <td>{referral.customer.bank_details.account_no}</td>
              <td>{referral.customer.bank_details.ifsc}</td>
              <td>{referral.customer.bank_details.upi_id}</td>
              <td>{referral.customer.bank_details.upi_mobile_number}</td>

            </tr>
          </tbody>
        </table>
      </div>
      {/* <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-truck-moving"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Order info</h6>
            <p className="mb-1">
              Shipping: {order.order_status_url} <br /> Pay method:{" "}
              {order.financial_status}
            </p>
          </div>
        </article>
      </div> */}
      {/* <div className="col-md-6 col-lg-4">
        <article className="icontext align-items-start">
          <span className="icon icon-sm rounded-circle alert-success">
            <i className="text-success fas fa-map-marker-alt"></i>
          </span>
          <div className="text">
            <h6 className="mb-1">Deliver to</h6>
            <p className="mb-1">
              Address: {order.order_status_url}
              
            </p>
          </div>
        </article>
      </div> */}
    </div>
  );
};

export default ReferralDetailInfo;
