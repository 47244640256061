import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { userListReducer, userLoginReducer } from "./Reducers/userReducers";

import {
  orderDeliveredReducer,
  orderDetailsReducer,
  orderListReducer,
} from "./Reducers/OrderReducres";

import {
  referralUpdateReducer,
  referralDetailsReducer,
  referralListReducer,
} from "./Reducers/ReferralReducers";

const reducer = combineReducers({
  userLogin: userLoginReducer,
  userList: userListReducer,
  orderList: orderListReducer,
  orderDetails: orderDetailsReducer,
  orderDeliver: orderDeliveredReducer,
  referralList: referralListReducer,
  referralDetails: referralDetailsReducer,
  referralUpdate: referralUpdateReducer,
});

// login
const userAInfoFromLocalStorage = localStorage.getItem("userAInfo")
  ? JSON.parse(localStorage.getItem("userAInfo"))
  : null;

const initialState = {
  userLogin: { userAInfo: userAInfoFromLocalStorage },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;
