import {
  REFERRAL_UPDATE_FAIL,
  REFERRAL_UPDATE_REQUEST,
  REFERRAL_UPDATE_SUCCESS,
  REFERRAL_DETAILS_FAIL,
  REFERRAL_DETAILS_REQUEST,
  REFERRAL_DETAILS_SUCCESS,
  REFERRAL_LIST_FAIL,
  REFERRAL_LIST_REQUEST,
  REFERRAL_LIST_SUCCESS,
} from "../Constants/ReferralConstants";
import { logout } from "./userActions";
import axios from "axios";

export const listreferralstopay = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_LIST_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/completed`, config);

    dispatch({ type: REFERRAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_LIST_FAIL,
      payload: message,
    });
  }
};

export const listreferralspartial = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_LIST_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/partial`, config);

    dispatch({ type: REFERRAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_LIST_FAIL,
      payload: message,
    });
  }
};

export const listreferralspaycompleted = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_LIST_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/payed`, config);

    dispatch({ type: REFERRAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_LIST_FAIL,
      payload: message,
    });
  }
};

export const listreferralsall = () => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_LIST_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/all`, config);

    dispatch({ type: REFERRAL_LIST_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_LIST_FAIL,
      payload: message,
    });
  }
};

// REFERRAL DETAILS
export const getReferralDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_DETAILS_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/promos/${id}`, config);
    dispatch({ type: REFERRAL_DETAILS_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_DETAILS_FAIL,
      payload: message,
    });
  }
};

// REFERRAL payment status update
export const updateReferral = (referral) => async (dispatch, getState) => {
  try {
    dispatch({ type: REFERRAL_UPDATE_REQUEST });

    const {
      userLogin: { userAInfo },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${userAInfo.token}`,
      },
    };

    const { data } = await axios.post(
      `/api/promos/updatepromo`,
      referral,
      config
    );
    dispatch({ type: REFERRAL_UPDATE_SUCCESS, payload: data });
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    if (message === "Not authorized, token failed") {
      dispatch(logout());
    }
    dispatch({
      type: REFERRAL_UPDATE_FAIL,
      payload: message,
    });
  }
};
