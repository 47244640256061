export const REFERRAL_LIST_REQUEST = "REFERRAL_LIST_REQUEST";
export const REFERRAL_LIST_SUCCESS = "REFERRAL_LIST_SUCCESS";
export const REFERRAL_LIST_FAIL = "REFERRAL_LIST_FAIL";

export const REFERRAL_DETAILS_REQUEST = "REFERRAL_DETAILS_REQUEST";
export const REFERRAL_DETAILS_SUCCESS = "REFERRAL_DETAILS_SUCCESS";
export const REFERRAL_DETAILS_FAIL = "REFERRAL_DETAILS_FAIL";

export const REFERRAL_UPDATE_REQUEST = "REFERRAL_UPDATE_REQUEST";
export const REFERRAL_UPDATE_SUCCESS = "REFERRAL_UPDATE_SUCCESS";
export const REFERRAL_UPDATE_FAIL = "REFERRAL_UPDATE_FAIL";
export const REFERRAL_UPDATE_RESET = "REFERRAL_UPDATE_RESET";

export const REFERRAL_LIST_MY_REQUEST = "REFERRAL_LIST_MY_REQUEST";
export const REFERRAL_LIST_MY_SUCCESS = "REFERRAL_LIST_MY_SUCCESS";
export const REFERRAL_LIST_MY_FAIL = "REFERRAL_LIST_MY_FAIL";
export const REFERRAL_LIST_MY_RESET = "REFERRAL_LIST_MY_RESET";