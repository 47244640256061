export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_LIST_REQUEST = "USER_LIST_REQUEST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAIL = "USER_LIST_FAIL";
export const USER_LIST_RESET = "USER_LIST_RESET";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";
export const USER_DETAILS_RESET = "USER_DETAILS_RESET";

export const USER_UPDATE_PROFILE_REQUEST = "USER_UPDATE_PROFILE_REQUEST";
export const USER_UPDATE_PROFILE_SUCCESS = "USER_UPDATE_PROFILE_SUCCESS";
export const USER_UPDATE_PROFILE_FAIL = "USER_UPDATE_PROFILE_FAIL";
export const USER_UPDATE_PROFILE_RESET = "USER_UPDATE_PROFILE_RESET";

// User send OTP constants
export const USER_SEND_OTP_REQUEST = "USER_SEND_OTP_REQUEST";
export const USER_SEND_OTP_SUCCESS = "USER_SEND_OTP_SUCCESS";
export const USER_SEND_OTP_FAIL = "USER_SEND_OTP_FAIL";

// User verify OTP constants
export const USER_VERIFY_OTP_REQUEST = "USER_VERIFY_OTP_REQUEST";
export const USER_VERIFY_OTP_SUCCESS = "USER_VERIFY_OTP_SUCCESS";
export const USER_VERIFY_OTP_FAIL = "USER_VERIFY_OTP_FAIL";

// User resend OTP constants
export const USER_RESEND_OTP_REQUEST = "USER_RESEND_OTP_REQUEST";
export const USER_RESEND_OTP_SUCCESS = "USER_RESEND_OTP_SUCCESS";
export const USER_RESEND_OTP_FAIL = "USER_RESEND_OTP_FAIL";
