import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

const Referrals = (props) => {
  const { referrals } = props;

  if (!referrals || !Array.isArray(referrals)) {
    return <div>No referrals available.</div>;
  }
  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Mail</th>
          <th scope="col">Referral Code</th>
          <th scope="col">Ref Count</th>
          <th scope="col">Value</th>
          <th scope="col">Remarks</th>
          <th scope="col">Updated At</th>
          <th>Attempt</th>
          <th scope="col" className="text-end">
            Action
          </th>
        </tr>
      </thead>
      <tbody>
        {referrals.map((referral) => (
          <tr key={referral._id}>
            <td>{referral.customer.email}</td>
            <td>
              <b>{referral.referral_code}</b>
            </td>
            <td>{referral.referral_count}</td>
            <td>{referral.referral_value}</td>

            <td>{referral.remarks}</td>
            <td>{moment(referral.updatedAt).format("MMM Do YY")}</td>
            <td>{referral.attempt_count}</td>
            <td className="d-flex justify-content-end align-item-center">
              <Link to={`/ReferralDetailScreenAll/${referral._id}`} className="text-success">
                <i className="fas fa-eye"></i>
              </Link>
            </td>
          </tr>
        ))}

        {/* Not paid Not delivered */}
        {/* <tr>
          <td>
            <b>Velcro Sneakers For Boys & Girls (Blue)</b>
          </td>
          <td>user@example.com</td>
          <td>$45,789</td>
          <td>
            <span className="badge rounded-pill alert-danger">Not paid</span>
          </td>
          <td>Dec 12 2021</td>
          <td>
            <span className="badge btn-dark">Not Delivered</span>
          </td>
          <td className="d-flex justify-content-end align-item-center">
            <Link to={`/order`} className="text-success">
              <i className="fas fa-eye"></i>
            </Link>
          </td>
        </tr> */}
      </tbody>
    </table>
  );
};

export default Referrals;
