import React from "react";
import Sidebar from "../components/sidebar";
import Header from "../components/Header";
import ReferralMain from "../components/ReferralsPayCompleted/ReferralMain";

const ReferralScreen = () => {
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <ReferralMain />
      </main>
    </>
  );
};

export default ReferralScreen;
