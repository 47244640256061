import React from "react";
import Sidebar from "./../components/sidebar";
import Header from "./../components/Header";
import ReferralDetailmain from "../components/ReferralsAll/ReferralDetailmain";

const ReferralDetailScreen = ({ match }) => {
  const referralId = match.params.id;
  return (
    <>
      <Sidebar />
      <main className="main-wrap">
        <Header />
        <ReferralDetailmain referralId={referralId} />
      </main>
    </>
  );
};

export default ReferralDetailScreen;
