import React from "react";
import { Link, NavLink } from "react-router-dom";

const Sidebar = () => {
  return (
    <div>
      <aside className="navbar-aside" id="offcanvas_aside">
        <div className="aside-top">
          <Link to="/" className="brand-wrap">
            <img
              src="/images/logo.svg"
              style={{ height: "46" }}
              className="logo"
              alt="Logo"
            />
          </Link>
          <div>
            <button className="btn btn-icon btn-aside-minimize">
              <i className="text-muted fas fa-stream"></i>
            </button>
          </div>
        </div>

        <nav>
          <ul className="menu-aside">
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/"
                exact={true}
              >
                <i className="icon fas fa-home"></i>
                <span className="text">Dashboard</span>
              </NavLink>
            </li>
       
          
           
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/orders"
              >
                <i className="icon fas fa-bags-shopping"></i>
                <span className="text">Orders</span>
              </NavLink>
            </li>
            {/* <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/users"
              >
                <i className="icon fas fa-user"></i>
                <span className="text">Users</span>
              </NavLink>
            </li> */}
            {/* <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link disabled"
                to="/sellers"
              >
                <i className="icon fas fa-store-alt"></i>
                <span className="text">Sellers</span>
              </NavLink>
            </li> */}

            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/referralstopay"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text">Referrals To Pay</span>
              </NavLink>
            </li>
            <li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/referralspartial"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text">Referrals Partial</span>
              </NavLink>
            </li><li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/referralspaycompleted"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text">Referrals Pay Completed</span>
              </NavLink>
            </li><li className="menu-item">
              <NavLink
                activeClassName="active"
                className="menu-link"
                to="/referralsall"
              >
                <i className="icon fas fa-usd-circle"></i>
                <span className="text">All Referral</span>
              </NavLink>
            </li>
          </ul>
          <br />
          <br />
        </nav>
      </aside>
    </div>
  );
};

export default Sidebar;
